import styled from "styled-components";
import { Energy, Logout } from "../../assets/images";
import { adminlistItems } from "./navlist";
import { useNavigate } from "react-router-dom";

const Navigation = ({ show, setShow }: any) => {
  const navigate = useNavigate();
  const r = {
    toggleNav(show: boolean) {
      setShow(!show);
    },

    getCurrentPath() {
      return localStorage.getItem("currentPath");
    },

    handleLogout() {
      localStorage.removeItem("token")
      localStorage.removeItem("user")
      navigate("/admin_hrc/login")
    }
  };

  return (
    <Transition>
      <NavContainer className={show ? "active" : "hidden"}>
        <Icon title="HRC" onClick={() => r.toggleNav(show)} />
        <NavListContainer>
          {adminlistItems.map(({ name, path, icon }) => (
            <NavList
              onClick={() => navigate(path)}
              style={
                r.getCurrentPath() === path
                  ? {
                      background:
                        "linear-gradient(65.58deg, #FFFFFF -11.84%, #FFF8C9 98.75%)"
                    }
                  : undefined
              }
            >
              <span title={name}>{icon}</span>
              <p className={!show ? "hidden" : undefined}>{name}</p>
            </NavList>
          ))}
        </NavListContainer>
        <BottomIcons>
          <LogoutIcon title="logout" onClick={() => r.handleLogout()} />
        </BottomIcons>
      </NavContainer>
    </Transition>
  );
};

const NavContainer = styled.div`
  position: fixed;
  width: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  top: 15px;
  bottom: 15px;
  left: 15px;
  margin: 0 auto;
  padding: 30px 15px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  z-index: 9999;
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
  border-radius: 20px;
`;

const Icon = styled.div`
  width: 40px;
  content: url(${Energy});
  border: 0px solid transparent;
  margin-bottom: 30px;
  cursor: pointer;
`;

const NavListContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 550;
  margin-top: 20px;
`;

const NavList = styled.div`
  margin: 0;
  padding: 15px 10px;
  display: flex;
  background-color: transparent;
  color: #CD5C08;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);

  span {
    margin-right: 10px;
  }

  p {
    padding: 0;
    margin: 0;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    position: relative;
    font-size: 14px;
    padding-top: 5px;

    &.hidden {
      display: none;
    }
  }

  &:hover,
  &:focus {
    background: linear-gradient(65.58deg, #ffffff -11.84%, #FFF8C9 98.75%);
    p {
      outline: 0;
    }
  }
`;

const Transition = styled.div`
  .active {
    width: 250px;
    transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
  }
  .hidden {
    width: 100px;
    transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
  }

  .showlist {
    visibility: visible;
  }
  .hidelist {
    visibility: hidden;
  }
`;

const BottomIcons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

const LogoutIcon = styled.div`
  width: 40px;
  margin-top: auto;
  content: url(${Logout});
  border: 0px solid transparent;
  cursor: pointer;
  margin-top: 10px;
`;

export default Navigation;
