import moment from "moment";
import { Tools } from "../../utils";


const columns = {
    titles: [
      'S/N',
      'Meter Number',
      'Token',
      'Amount',
      'Units',
      'Phone Number',
      'Email',
      'Trx Reference',
      'Trx Date',
      ''
    ],
    columns($: any) {
      return [
        { data: 'id' },
        { data: 'meterNumber' },
        { data: 'token' },
        { 
            data: 'amount',
            render: function (data: string) {
                return Tools.formatCurrency(parseInt(data))
            }
        
        },
        { data: 'units'},
        { data: 'phone'},
        { data: 'email'},
        { data: 'reference'},
        { 
            data: 'createdAt',
            render: function (data: string){
                return moment(data).format('DD/MM/YYYY h:mma')
            }
        },
        {
          data: 'id',
          createdCell: function (td: HTMLElement, cellData: string) {
              $(td).html(`<div>
                <a class="cell-button" href="/admin/transactions/update/${cellData}">Update</a>
              </div>`);
          },
      }
      ];
    },
  };


  export default columns;