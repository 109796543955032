import { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Desktop, Mobile } from '../../components/meter'

const Landing = () => {
    const [mobile, setMobile ] = useState(false)
    const mounted = useRef(false)
    useEffect(() => {
        mounted.current = true
        const responsive = () => {
          return window.innerWidth <= 768
          ? setMobile(true)
          : setMobile(false)
        }
        responsive()
        window.addEventListener('resize', () => responsive())


        return () => {
            window.removeEventListener('resize', () => responsive())
            mounted.current = false
        }
    })

  return (
    <AppContainer>
       <Helmet>
            <meta charSet="utf-8" />
            <title>Meter</title>
        </Helmet> 
        <Wrapper>
            { mobile ? <Mobile /> : <Desktop />}
        </Wrapper>
    </AppContainer>
  )
}

/** STYLES */
const AppContainer = styled.div`
min-height: 100vh;
width: 100%;
`;

const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export default Landing;