import styled from 'styled-components'
import Stepper from 'react-stepper-horizontal'
import { Screen } from '../styles'

const Steppers = ({ step }: any) => {
  return (
    <Box>
        <Wrapper>
          <h3>HRC Utilities Payment</h3>
          <Stepper
            steps={[{ title: 'Information' }, { title: 'Amount' }, { title: 'Confirm' }]}
            activeStep={step}
            
          />
        </Wrapper>

    </Box>
  )
}

const Box = styled.div`
width: 100%;
`
const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

${Screen.galaxyFold`
h3 {
  font-size: 16px;
}
`}
`
export default Steppers