import styled from "styled-components";
import { Decorate } from "../styles";


const Notify = ({ color, message }: any) => {
  return (
    <ErrorMessage
    style={{
      backgroundColor: color,
      border: color
    }}>
      {message}
    </ErrorMessage>
  );
}

export default Notify;


const ErrorMessage = styled.div`
  position: fixed;
  top: 50px;
  right: 20px;
  font-size: 15px;
  background-color: #FF6B62;
  padding: 20px;
  border: 1px solid  #FF6B62;
  border-radius: 5px;
  color: white;
  ${Decorate.animate.slideInRight(0.5)}
`;
