import { useState, useEffect, useRef } from 'react'
import Information from "./information";
import Amount from './amount';
import Confirm from './confirm';
import { api } from '../hooks';
import { Alert } from '../helpers';

const Mobile = () => {
    const [step, setStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [meterFound, setMeterFound] = useState(false)
    const [userData, setUserData] = useState({
        meter: '',
        amount: '',
        phone: '',
        email: '',
        product: ''

    })

    const mounted = useRef(false)

    const { meter, amount, phone, email, product } = userData

    useEffect(() => {
        mounted.current = true
        const handleMeter = async () => {
            try {
                if(meter.length === 11){
                    setLoading(true)
                    const { data } = await api.get(`/meter/${meter}`)
                    setLoading(false)
                    if(data.response.status === "failure"){
                        setShowAlert(true)
                        setMeterFound(false)
                        setAlertMessage(data.response.message)
                        setTimeout(() => setShowAlert(false), 3000)
                        return;
                    }
                    setMeterFound(true)
                }
            } catch (error) {
                
            }
        }
        handleMeter()

        return () => {
            mounted.current = false
        }
    }, [meter])

    const r = {

        validateMeter(e: React.ChangeEvent<HTMLInputElement>){
            const meterRegex = /^[0-9\b]{1,11}$/;
            if(e.target.value === '' || meterRegex.test(e.target.value)){
                setUserData({
                    ...userData,
                    meter: e.target.value.replace(" ", "")
                })
            }
        },

        onChange(e: React.ChangeEvent<HTMLInputElement>){
            setUserData({
                ...userData,
                [e.target.name]: e.target.value.replace(" ", "")
            })
        },
        nextStep(){
            setStep(prevState => prevState + 1)
        },

        prevStep(){
            setStep(prevState => prevState - 1)
        }
    }

    switch(step) {
        case 0:
          return (
            <>
              <Information
              meter={meter}
              step={step}
              phone={phone}
              email={email}
              product={product}
              r={r}
              loading={loading}
              meterFound={meterFound}
              />
              { showAlert && <Alert message={alertMessage} />}
            </>
            )
        case 1:
          return (
            <Amount
            r={r}
            amount={amount}
            step={step}
             />
        )
        case 2:
          return (
            <Confirm
            meter={meter}
            amount={amount}
            step={step}
            phone={phone}
            email={email}
            product={product}
            r={r}
             />
        )
          default:
            return null
    }
}

export default Mobile