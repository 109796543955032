import React, { useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Energy } from '../../assets/images'
import { MdOutlineVisibilityOff, MdOutlineVisibility } from "react-icons/md";
import { api } from '../../components/hooks';
import { useNavigate } from 'react-router-dom';
import { Alert, Loader } from '../../components/helpers';


const Login = () => {

    const [userData, setUserData] = useState({
        email: "",
        password: ""
    })
    const [showPassword, setShowPassword] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [color, setColor] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const { password, email } = userData;

    const r = {
        onChange(e: React.ChangeEvent<HTMLInputElement>){
            setUserData({
                ...userData,
                [e.target.name]: e.target.value.replace(" ", ""),
            })
        },
        revealPassword(){
            setShowPassword(!showPassword)
        },

        async onSubmit(e: React.KeyboardEvent<HTMLInputElement> | React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLDivElement>){
          try {
            e.preventDefault()
            setLoading(true)
            const data = await r.handleLogin()
            setLoading(false)
            if(data?.response?.status === "success"){
              localStorage.setItem("token", data.response.token)
              localStorage.setItem("user", JSON.stringify(data.response.user))
              setShowAlert(true)
              setAlertMessage(data?.response?.message)
              setColor("#64CCC5")
              setTimeout(() => setShowAlert(false), 3000)
              setTimeout(() => navigate("/admin/meters"), 4000)
            } else {
              setShowAlert(true)
              setAlertMessage(data?.response?.message ? data?.response?.message : data)
              setTimeout(() => setShowAlert(false), 3000)
            }
          } catch (error) {
          }
        },

        handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
          if (e.key === 'Enter') {
            r.onSubmit(e);
          }
        },

        async handleLogin(){
          try {
            const body = {
              email,
              password
            }
            const { data }= await api.post("/auth/admin/signin", body)
            return data;
          } catch (error: any) {
            let message = (error.response && error.response.data && error.response.data.message) 
            || error.message || error.toString();
            return message;
          }
        },
    }
  return (
    <AppContainer>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Login</title>
        </Helmet>
        <Wrapper>
            <ImageWrapper>
                <Image src={Energy} alt='logo' />
            </ImageWrapper>
        <LoginContainer>
          <h1>Welcome Back</h1>
          <p>Enter your credentials to access your account.</p>
          <FormContainer 
          onSubmit={(e) => r.onSubmit(e)}
          >
            <Input>
            <input
              type='email'
              placeholder='Enter your email'
              name='email'
              onChange={(e) => r.onChange(e)}
              value={email}
              onKeyDown={(e) => r.handleKeyPress(e)}
            />
            </Input>
            <Input>
            <input
              type={ showPassword ? 'text' : 'password'}
              placeholder='Enter your password'
              name='password'
              className='password'
              onChange={(e) => r.onChange(e)}
              value={password}
              onKeyDown={(e) => r.handleKeyPress(e)}
            />
            {password.length > 0 && (
                <Icon onClick={() => r.revealPassword()}>
                    {showPassword ? (
                      <MdOutlineVisibility
                        size={"1.5em"}
                        color={"#213F7D"}
                        title="Hide password"
                      />
                    ) : (
                      <MdOutlineVisibilityOff
                        size={"1.5em"}
                        color={"#213F7D"}
                        title="Show password"
                      />
                    )}
                </Icon>
            )}
            </Input>
            {
              loading 
              ? <Button><Loader color={'#fff'} /></Button> 
              : <Button onClick={(e) => r.onSubmit(e)} className={(!email || !password) ? "disabled" : undefined}>Login</Button>
            }
          </FormContainer>
        </LoginContainer>
        </Wrapper>
        {showAlert && <Alert message={alertMessage} color={color} />}
    </AppContainer>
  )
}

/** STYLES */
const AppContainer = styled.div`
background-color: #F8F0E5;
min-height: 100vh;
width: 100%;
padding-top: 130px;
`;

const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;
const ImageWrapper = styled.div`

`;
const Image = styled.img`
width: 80px;
height: 80px;
`;

const LoginContainer = styled.div`
  display: flex;
  width: 450px;
  height: auto;
  flex-direction: column;
  padding: 50px 30px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  margin: 2rem;

  h1 {
    font-size: 19px;
    font-weight: 800;
    font-family: 'Montserrat', sans-serif;
    align-self: center;
    color: #CD5C08;
    margin: 0;
    margin-bottom: 5px;
  }

  p {
    font-size: 11px;
    font-weight: 400;
    align-self: center;
    color: #989da6;
    margin: 0;
  }
`;

const FormContainer = styled.form`
margin-top: 10px;
display: flex;
flex-direction: column;

  input{
    width: 100%;
    margin-top: 30px;
    border: 1px solid #f0f0f0;
    padding: 15px 30px;
    border-radius: 5px;
    background-color: transparent;
    color: #44115C;
  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
`;

const Input = styled.div`
position: relative;
`;

const Button = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 15px 30px;
  border-radius: 5px;
  color: white;
  outline: none;
  background-color: #CD5C08;
  font-size: 12px;
  cursor: pointer;
  justify-content: center;

  &.disabled {
    pointer-events: none;
    background-color: #ddd;
    color: #CD5C08;
  }
`;

const Icon = styled.div`
  position: absolute;
  top: 2.5rem;
  display: flex;
  right: 1rem !important;
  cursor: pointer;
`;

export default Login