import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Sterling } from '../../assets/images'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { Tools } from '../../utils'
import { Screen } from '../../components/styles'



const Receipt = () => {
    const [request, setRequest] = useState<any>()
    const navigate = useNavigate()
    useEffect(() => {
        setRequest(JSON.parse(localStorage.getItem("details") as string))
    }, [])

  return (
    <AppContainer>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Receipt</title>
        </Helmet>
        <Wrapper>
            <ButtonWrapper>
                <Button onClick={() => Tools.snapshot("receipt", "payment_receipt")}>Download Receipt</Button>
                <Button onClick={() => navigate("/")}>Back to homepage</Button>
            </ButtonWrapper>
            <Card id='receipt'>
                <Icon title='Sterling Tech' />
                <Header>Receipt</Header>
                <Container>
                    <Text>Meter Number: <span>{request?.meterNumber}</span></Text>
                    <Text>Product: <span>{request?.product}</span></Text>
                    <Text>Amount: <span>{Tools.formatCurrency(request?.amount)}</span></Text>
                    <Text>Email: <span>{request?.email}</span></Text>
                    <Text>Phone Number: <span>{request?.phone}</span></Text>
                    <Text>Reference: <span>{request?.reference}</span></Text>
                    <Text>Date: <span>{moment(new Date(Date.now())).format("YYYY-MM-DD h:mma")}</span></Text>
                </Container>
                <Enquiry>
                    <p>For complaints and enquiries, kindly reach us through:</p>
                    <p><span>Phone Number:</span> 08080061113</p>
                    <p><span>Email:</span> hrcwaterco@gmail.com</p>
                </Enquiry>
            </Card>
        </Wrapper>
    </AppContainer>
  )
}

/** STYLES */
const AppContainer = styled.div`
background-color: #F8F0E5;
min-height: 100vh;
width: 100%;
padding-top: 70px;
overflow-x: hidden;

${Screen.iPhone`
    padding-top: 30px
`}
`;

const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

${Screen.iPhone`
    width: 100%
`}
`;

const Container = styled.div`
width: 80%;
margin-top: 2rem;

`

const Card = styled.div`
  display: flex;
  width: 450px;
  height: auto;
  flex-direction: column;
  padding: 50px 30px;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  margin: 1rem 2rem 2rem;
  position: relative;

  ${Screen.iPhone`
    width: 90%;
`}
`;

const Icon = styled.div`
width: 20px;
position: absolute;
top: 1rem;
left: 1rem;
content: url(${Sterling});
cursor: pointer;
`

const Header = styled.h2`
text-align: center;
color: #CD5C08;
`

const Button = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 15px 10px;
  border-radius: 5px;
  color: white;
  outline: none;
  background-color: #CD5C08;
  font-size: 12px;
  cursor: pointer;
  justify-content: center;

  ${Screen.iPhone`
    padding: 15px;
`}
`;

const Text = styled.h5`
padding-bottom: 1rem;

span {
    font-weight: 400;
}
`;

const ButtonWrapper = styled.div`
width: 450px;
display: flex;
justify-content: space-between;

${Screen.iPhone`
    flex-direction: column;
    justify-content: center;
    align-items: center;
`}
/* margin-top: 1rem; */
`;

const Enquiry = styled.div`
margin-top: 2rem;

p {
    font-size: 14px;
    padding-bottom: .5rem;

    span {
        color: #CD5C08;
    }
}
`

export default Receipt