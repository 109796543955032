import {  AiOutlineTransaction } from 'react-icons/ai'
import { BsSpeedometer2 } from 'react-icons/bs'

export const adminlistItems = [
    // {
    //     name: 'Dashboard',
    //     path: '/admin/dashboard',
    //     icon: <AiFillHome size={'1.5rem'} />
    // },
    {
        name: 'Meters',
        path: '/admin/meters',
        icon: <BsSpeedometer2 size={'1.5rem'} />
    },
    {
        name: 'Transactions',
        path: '/admin/transactions',
        icon: <AiOutlineTransaction size={'1.5rem'} />
    }
]


export const meterData = [
    {
        "id": 4,
        "meterNumber": "97849788489",
        "owner": "Olaife Olawore",
        "addedBy": "Esho Oluwasegun",
        "createdAt": "2023-10-09T15:40:08.000Z"
    },
    {
        "id": 3,
        "meterNumber": "28926298714",
        "owner": "Balogun Ademola",
        "addedBy": "Esho Oluwasegun",
        "createdAt": "2023-10-09T08:33:14.000Z"
    },
    {
        "id": 2,
        "meterNumber": "28926298715",
        "owner": "Akojede Peter",
        "addedBy": "Esho Oluwasegun",
        "createdAt": "2023-10-09T07:18:16.000Z"
    }
]

export const transactionData = [
    {
        "id": 2,
        "token": "52344326098365121837",
        "meterNumber": "97849788489",
        "amount": "2500",
        "units": 50,
        "createdAt": "2023-10-09T15:43:11.000Z"
    },
    {
        "id": 1,
        "token": "15154978390199717459",
        "meterNumber": "28926298715",
        "amount": "5000",
        "units": 100,
        "createdAt": "2023-10-09T14:48:05.000Z"
    }
]