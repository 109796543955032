/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import datatable from 'datatables.net';
import * as jszip from 'jszip';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-fixedcolumns';
import { MeterColumns } from '../columns';


// TODO: Fix types issue here to allow import statement
const $: any = require('jquery');

window.JSZip = jszip
$.DataTable = datatable;

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.text};
  label {
    color: ${({ theme }) => theme.text};
    margin-bottom: 10px;
    margin-left: 10px;

    input {
      color: ${({ theme }) => theme.text};
    }
  }

  table.dataTable thead th:first-child {
    border-radius: 10px 0px 0px 0px;
  }
  table.dataTable thead th:last-child {
    border-radius: 0px 10px 0px 0px;
  }

  table.dataTable button {
    background-color: black;
  }

  .hover-action {
    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.tablehover};
    }
  }

  .cell-button {
    margin: 0px;
    border: 1px solid #CD5C08;
    color: white;
    padding: 10px 20px;
    background-color: #CD5C08;
    border-radius: 10px;
    text-decoration: none;
  }

  a {
    color: #ffff;
  }
`;

const App = ({ data  }: any) => {
  const mounted = useRef(false);
  
  const [table, setTable] = useState(null);
  const [tableId, setTableId] = useState(null);

  const setTableRef = async (e: any) => {
    if (e === null) return;
    setTable(e);
    setTableId(e.getAttribute('id'));
  };

  useEffect(() => {
    if (!table) return;
    $(`#${tableId}`).DataTable().destroy(true);
    const datatable = $(table);
    datatable.DataTable({
      data: data,
      columns: MeterColumns.columns($),
      dom: 'Bfrtip',
      scrollY: '500px',
      scrollX: true,
      scrollCollapse: true,
      paging: true,
      order: [],
      pageLength: 25,
      buttons: ['excel'],
      columnDefs: [
        {
          data: null,
          targets: -1
        }
      ],
      createdRow: function (row: HTMLElement) {
        $(row).addClass('hover-action');
      },
      language: { search: "", searchPlaceholder: "Search" }
    });

  
    return () => {
      datatable.DataTable().destroy(true);
    };
  }, [table]);

  useEffect(() => {
    mounted.current = true;
    if (!table) return;
    $(`#${tableId}`).DataTable().clear();
    $(`#${tableId}`).DataTable().rows.add(data);
    $(`#${tableId}`).DataTable().draw();
    return () => {
      mounted.current = false;
    };
  }, [data]);

  const titles = MeterColumns.titles.map((value: any, i: React.Key) => <th
  key={i}
  style={{
    width: '250px',
    height: '30px',
    backgroundColor: '#F8F0E5',
    color: '#CD5C08',
    fontSize: '12px',
    border: '1px transparent',
    // fontWeight: '400'
  }}>{value}</th>
);

  return (
    <TableContainer>
      <table  width={'100%'} className="cell-border row-border" ref={(e) => setTableRef(e)}>
        <thead>
          <tr>
            {titles}
          </tr>
        </thead>
      </table>
    </TableContainer>
  );
};

export default App;
