import React, { useState } from 'react'
import { PaymentProps } from '../../types'
import styled from 'styled-components';
import Steppers from './stepper';
import { closePaymentModal, useFlutterwave } from 'flutterwave-react-v3';
import { api } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { Alert, Loader } from '../helpers';
import { Screen } from '../styles';
import { Tools } from '../../utils';

const Confirm = ({ meter, amount, step, phone, email, product, r }: PaymentProps) => {

  const[ loading, setLoading ] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const navigate = useNavigate()

  const serviceCharge = 0.015 * Number(amount)
  const totalAmount =  Number(amount) + serviceCharge

    const subAccount = product === "power" ? process.env.REACT_APP_POWER_SUB_ACCOUNT : process.env.REACT_APP_WATER_SUB_ACCOUNT

    const handleFlutterwave = useFlutterwave(Tools.paymentConfig(totalAmount, email, phone, subAccount, product) as any);

    const handleTransaction = async (reference: string) => {
      try {
        setLoading(true)
        const body = {
          meterNumber: meter,
          product,
          amount: totalAmount,
          email,
          phone,
          reference
        }
        localStorage.setItem("details", JSON.stringify(body))
        const { data } = await api.post('/transaction/add', body)
        setLoading(false)
        if(data.response.status === "success"){
          localStorage.setItem("date", data.response.transaction.createdAt)
        }
      } catch (error) {
        
      }
    }

    const handlePayment = () => {
      try {
        handleFlutterwave({
            callback: response => {
                closePaymentModal()
                if(response.status === "successful" || response.status === "completed"){
                  navigate("/receipt")
                  handleTransaction(response.tx_ref);
                } else {
                  setShowAlert(true)
                  setAlertMessage("Transaction Failed")
                  setTimeout(() => setShowAlert(false), 3000)
                }
            },
            onClose: () => {},
        })
      } catch (error) {
        
      }
    }
  return (
    <Box>
    <Card>
    <Steppers step={step} />
    <FormContainer>
            <input
              readOnly={true}
              value={product}
            />
            <input
                readOnly={true}
                value={meter}
            />
            <input
              readOnly={true}
              value={phone}
            />
            <input
              readOnly={true}
              value={email}
            />
            <input
              readOnly={true}
              value={amount}
            />
            <p>Service Charge: {Tools.formatCurrency(serviceCharge)}</p>
            <ButtonWrapper>
            <Button onClick={() => r.prevStep()}>Back</Button>
            {
              loading 
              ? <Button> <Loader color={"#fff"} /></Button>
              : <Button onClick={handlePayment}>Pay</Button>
            }
            </ButtonWrapper>
    </FormContainer>
    </Card>
    {showAlert && <Alert message={alertMessage} />}
    </Box>
  )
}

const Box = styled.div`
width: 100%;
min-height: 100vh;
background-color: #F8F0E5;
`;

const Card = styled.div`
width: 350px;
margin: 5rem auto;
padding: 3rem 2rem;
background-color: #fff;

${Screen.smallPhone`
width: 90%

`}
`;
const FormContainer = styled.form`
margin: 10px auto;

  input{
    width: 100%;
    margin-top: 20px;
    border: 1px solid #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    background-color: transparent;
    color: #44115C;
  }

  p {
    margin-top: 1rem;
    color: #44115C;
  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
`;

const Button = styled.div`
  display: flex;
  margin-top: 30px;
  padding: 10px 20px;
  border-radius: 5px;
  color: white;
  outline: none;
  background-color: #CD5C08;
  font-size: 15px;
  cursor: pointer;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
display: flex;
justify-content: space-between;
/* margin-top: 1rem; */
`;

// const Text = styled.h6`
// color: #999;
// font-size: 18px;
// font-weight: 400;
// `;

// const TextWrapper = styled.div`
// display: flex;
// flex-direction: column;
// justify-content: center;
// margin-top: 2rem;
// `
export default Confirm